import React, { useEffect, useState } from "react";

import "./App.css";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Nav from "./components/Nav";
import Header from "./components/Header";
import { Link, animateScroll as scroll } from "react-scroll";
const Jufopay = React.lazy(() => import("./components/Jufopay"));
const Mission = React.lazy(() => import("./components/Mission"));
const Products = React.lazy(() => import("./components/Products"));
const Subscribe = React.lazy(() => import("./components/Subscribe"));
const Management = React.lazy(() => import("./components/Management"));
const Form = React.lazy(() => import("./components/Form"));
const Footer = React.lazy(() => import("./components/Footer"));

function App() {
  const [button, setbutton] = useState(false);

  useEffect(() => {
    /*
     window.scrollBy({ bottom: 100, left: 0, behavior: "smooth" });
    
    */
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setbutton(true);
      } else {
        setbutton(false);
      }
    });
  });
  const scrollTop = () => {
    scroll.scrollTo();
  };

  return (
    <HashRouter hashType="noslash">
      <div className="App">
        <Nav />
        <Header />
        <React.Suspense fallback={<p>Loading...</p>}>
          <main>
            <Jufopay />
            <Mission />
            <Products />
            <Subscribe />
            <Management />
            <Form />
          </main>
          <Footer />
        </React.Suspense>
        {button && (
          <button onClick={scrollTop} className="scroller">
            <HashLink to="../">&#8679;</HashLink>
          </button>
        )}
      </div>
    </HashRouter>
  );
}

export default App;

import { Link, animateScroll as scroll } from "react-scroll";
import { useState } from "react";
import logo from "../images/Logo.svg";
import { MdClose } from "react-icons/md";
import { FaBars } from "react-icons/fa";

const Nav = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };
  return (
    <div className="nav-wrap">
      <nav>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <ul
          className={click ? "menulinks active" : "menulinks"}
          onClick={() => setClick(!click)}
        >
          {/* <li>
                <HashLink to="#products">Our Products</HashLink>
              </li> */}

          <Link
            to="products"
            duration={2000}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-1}
          >
            <li onClick={() => setClick(!click)}>Our Products</li>
          </Link>

          {/* <li>
                <HashLink to="#mission">About</HashLink>
              </li> */}

          <Link
            to="mission"
            duration={2000}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-1}
          >
            <li onClick={() => setClick(!click)}>About Us</li>
          </Link>

          {/* <li>
                <HashLink to="#form">Contact Us</HashLink>
              </li> */}

          <Link
            to="form"
            duration={3000}
            activeClass="active"
            spy={true}
            offset={-1}
            smooth={true}
          >
            <li onClick={() => setClick(!click)}>Contact Us</li>
          </Link>
        </ul>
        <div className="toggle" onClick={handleClick}>
          {click ? <MdClose className="bar-close" /> : <FaBars />}
        </div>
      </nav>
    </div>
  );
};

export default Nav;

import logo from "../images/Logo.svg";
import Carousel from "./Carousel";
import scrollImage from "../images/scroll-down.svg";

import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
  const scrollDown = () => {
    scroll.scrollTo(3600);
  };

  return (
    <div className="header-container">
      <header className="header">
        <div className="header-div">
          <div className="hero-wrap">
            <div className="hero">
              <div className="left-hero">
                <h1>Banking For Better Living</h1>
                <p>
                  We are a microfinance bank licensed by the Central Bank of
                  Nigeria. Our mission is to use digital banking tools to make
                  life easier for our customers anytime, anywhere and anyhow. We
                  focus on individuals and small businesses.
                </p>
                <img
                  src={scrollImage}
                  alt=""
                  className="scrollImage"
                  onClick={scrollDown}
                />
              </div>
              <div className="hero-right">
                {/* <article className="down-right">
                <p>
                  We provide a broad range of tailored financial services for
                  MSMEs, the middle and informal economy across Africa.
                </p>
              </article> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Carousel /> */}
    </div>
  );
};

export default Header;
